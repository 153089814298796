/* eslint-disable react/style-prop-object */
import * as React from "react";
import { Button, Checkbox } from "@emisgroup/ui-kit-react";
import { useTranslation } from "@emisgroup/application-intl";
import { HistoryItem, HistoryQualifier, TemplateHistoryContext } from "../context/templateHistory";

import "./codeHistory.css";

type CodeHistoryProps = { title: string; historyKey: string; onClose: () => void };

const getAdditionalHistoryCount = (hasValue, hasQualifiers) => {
    if (!hasQualifiers && !hasValue) return 0;
    if (!hasQualifiers || !hasValue) return 1;
    return 2;
};
const getLayoutClass = additionalCount => {
    if (additionalCount === 1) return "additional-one-layout";
    if (additionalCount === 2) return "additional-two-layout";
    return "";
};

const HistoryHeaderColumns = ({ hasValue, hasQualifiers }) => {
    const { t } = useTranslation();
    const additionalCount = getAdditionalHistoryCount(hasValue, hasQualifiers);
    return (
        <div className={`history-list-column-header ${getLayoutClass(additionalCount)}`}>
            <div data-testid="header-date" className="header-date">
                {t("components.history.date")}
            </div>
            <div data-testid="header-term" className="header-term">
                {t("components.history.term")}
            </div>
            {hasValue && <div data-testid="header-value">{t("components.history.value")}</div>}
            {hasQualifiers && <div data-testid="header-qualifier">{t("components.history.qualifiers")}</div>}
        </div>
    );
};

type HistoryQualifierItemProps = { qualifier: HistoryQualifier };
const HistoryQualifierItem = ({ qualifier }: HistoryQualifierItemProps) => (
    <>
        <div data-testid="item-qualifier-key" className="history-item-title-qualifier">
            {qualifier.key}:
        </div>
        <div data-testid="item-qualifier-value" className="history-item-qualifier-value">
            {qualifier.value}
        </div>
    </>
);

const HistoryListItem = ({
    isFirstInGroup,
    isLastInGroup,
    date,
    item,
    listHasValue,
    hasQualifiers,
    showAssociatedText,
}) => {
    const { term, value, associatedText, qualifiers } = item;
    const { t } = useTranslation();
    const additionalCount = getAdditionalHistoryCount(listHasValue, hasQualifiers);
    return (
        <>
            <div className={`history-list-item ${getLayoutClass(additionalCount)}`}>
                <div className="history-item-title date"> {t("components.history.date")}</div>
                <div data-testid="item-date" className={`history-item-date ${isFirstInGroup ? "first-in-group" : ""}`}>
                    {date}
                </div>
                <div className="history-item-title term">{t("components.history.term")}</div>
                <div data-testid="item-term" className="history-item-term">
                    {term}
                </div>
                {showAssociatedText && associatedText && (
                    <div data-testid="item-associated-text" className="history-associated-text-item">
                        {associatedText}
                    </div>
                )}
                {listHasValue && (
                    <>
                        <div className="history-item-title value">{t("components.history.value")}</div>
                        <div data-testid="item-value" className="history-item-value">
                            {value}
                        </div>
                    </>
                )}
                {hasQualifiers && qualifiers && (
                    <>
                        <div className="history-item-title qualifiers">{t("components.history.qualifiers")}</div>
                        <div className={`history-item-qualifiers ${getLayoutClass(additionalCount)}`}>
                            {React.Children.toArray(
                                qualifiers.map(qualifier => <HistoryQualifierItem qualifier={qualifier} />),
                            )}
                        </div>
                    </>
                )}
            </div>
            {!isLastInGroup && <div className="item-separator" />}
        </>
    );
};

const HistoryGroup = ({ date, items, showAssociatedText, hasValue, hasQualifiers }) => (
    <div className="history-list-group" data-testid="history-list-group">
        {items.map((item, index) => (
            <HistoryListItem
                key={item}
                isFirstInGroup={index === 0}
                isLastInGroup={index === items.length - 1}
                date={date}
                item={item}
                showAssociatedText={showAssociatedText}
                listHasValue={hasValue}
                hasQualifiers={hasQualifiers}
            />
        ))}
    </div>
);

const HistoryListItems = ({ groupedData, showAssociatedText, hasValue, hasQualifiers }) => (
    <div className="history-list-items">
        {Object.entries(groupedData).map(([date, items]) => (
            <HistoryGroup
                key={date}
                date={date}
                items={items}
                showAssociatedText={showAssociatedText}
                hasValue={hasValue}
                hasQualifiers={hasQualifiers}
            />
        ))}
    </div>
);

const groupHistory = (history: HistoryItem[]) =>
    history.reduce((grouped, item) => {
        const date = item.date || "unknown";
        return {
            ...grouped,
            [date]: grouped[date] ? [...grouped[date], item] : [item],
        };
    }, {});

type HistoryListProps = { data: HistoryItem[]; showAssociatedText?: boolean };
const HistoryList = ({ data, showAssociatedText }: HistoryListProps) => {
    const groupedData = groupHistory(data);
    const hasValue = data.some(item => typeof item.value !== "undefined");
    const hasQualifiers = data.some(item => (item.qualifiers?.length ?? 0) > 0);
    return (
        <div className="history-list">
            <HistoryHeaderColumns hasValue={hasValue} hasQualifiers={hasQualifiers} />
            <HistoryListItems
                groupedData={groupedData}
                showAssociatedText={showAssociatedText}
                hasValue={hasValue}
                hasQualifiers={hasQualifiers}
            />
        </div>
    );
};

const CodeHistory = ({ title, historyKey, onClose }: CodeHistoryProps) => {
    const { t } = useTranslation();
    const { history } = React.useContext(TemplateHistoryContext);
    const [showAssociatedText, setShowAssociatedText] = React.useState(true);

    const handleShowAssociatedTextChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setShowAssociatedText(ev.currentTarget.checked);
    };

    const data = history[historyKey];
    const hasAssociatedText = data.some(item => item.associatedText);

    return (
        <div className="code-history-container" aria-label={t("components.history.historyForTitle", { title })}>
            <div className="code-history-header">
                <div className="code-history-header-text">{title}</div>
                {hasAssociatedText && (
                    <Checkbox
                        data-testid="associated-text-toggle"
                        checked={showAssociatedText}
                        labelText={t("components.history.associatedText")}
                        onChange={handleShowAssociatedTextChange}
                    />
                )}
                <Button
                    className="code-history-close-button"
                    iconOnly
                    iconName="close"
                    variant="secondary"
                    onClick={onClose}
                    ariaLabel={t("components.history.closeTitleHistory", { title })}
                />
            </div>
            <HistoryList data={data} showAssociatedText={showAssociatedText} />
        </div>
    );
};

export default CodeHistory;
