/* eslint-disable jsx-a11y/role-supports-aria-props */
import React from "react";
import {
    AppModeContext,
    AppMode,
    ComponentDataType,
    TypeOfProperty,
    ConditionalPropertyDataType,
    ConfigContext,
    PropertyDataTypeWithWarning,
    PickingListComponent,
    MultiSelectPropertyDataType,
} from "@emisgroup/clint-templates-common";
import { useTranslation } from "@emisgroup/application-intl";
import { PropsForProperty } from "../types";
import { capitalise, getStringPropertyDisplayValue } from "../utils/componentUtils";
import PropertyValue from "./propertyValue";
import CodeListPropertyEntry from "../properties/codeListPropertyEntry";
import PropertyWarning from "./propertyWarning";
import ValueListPropertyEntry from "../properties/valueListPropertyEntry";
import PropertyError from "./propertyError";
import { getSelectionsAsText } from "../utils/multiSelectPropertyUtils";

const DATA_TYPES_WITH_DIVIDER = [ComponentDataType.PARAMETER_LIST];

const Property = ({
    component,
    name,
    dataAttributes = { type: ComponentDataType.STRING },
    description,
    readOnly,
    onPropertyUpdate,
    propertyType,
    setFocus,
    validity = { isValid: true },
}: PropsForProperty) => {
    const { t } = useTranslation();
    const descriptionText = t(description);
    const { mode } = React.useContext(AppModeContext);
    const { features } = React.useContext(ConfigContext);
    const componentId = component.id;
    const propertyName = `${componentId}-property-${name}`;
    const hasDefinedValue = typeof component[name] !== "undefined" && Boolean(String(component[name]).trim());
    const valueToDisplay = () => {
        if (dataAttributes.type === ComponentDataType.ARRAY) {
            return component[name].map(entry => entry.label || "").join(",");
        }
        if (dataAttributes.type === ComponentDataType.NUMERIC) {
            return hasDefinedValue ? component[name] : undefined;
        }
        if (dataAttributes.type === ComponentDataType.CODE) {
            return hasDefinedValue ? component[name].term : t("codeEntry.noCodeSelected");
        }
        if (dataAttributes.type === ComponentDataType.CODE_LIST) {
            return hasDefinedValue ? component[name].map(({ term }) => term).join(",") : t("codeList.noCodesSelected");
        }
        if (dataAttributes.type === ComponentDataType.MULTI_SELECT_DROPDOWN) {
            const multiSelectPropertyDataType = dataAttributes as MultiSelectPropertyDataType;
            const options = component[name] ?? [];
            const selectedItemsText = getSelectionsAsText(t, options, multiSelectPropertyDataType.getOptions(features));
            return selectedItemsText ?? multiSelectPropertyDataType.placeHolder;
        }
        return hasDefinedValue ? getStringPropertyDisplayValue(t, name, component[name].toString()) : "";
    };

    const [selectedProperty, setSelectedProperty] = React.useState<string>("");

    React.useEffect(() => {
        if (setFocus) {
            setSelectedProperty(name);
        }
    }, [setFocus, name]);

    const isPropertySelected = Boolean(selectedProperty && selectedProperty === name);
    const isEditMode = mode === AppMode.EDIT;

    const handlePropertyValueClick = evt => {
        if (isEditMode) {
            setSelectedProperty(name);
            evt.stopPropagation();
        }
    };

    const isPropertyMandatory = propertyType === TypeOfProperty.MAIN;
    const isPropertyReadOnly = readOnly || !isEditMode;

    const conditionalAttribute = dataAttributes as ConditionalPropertyDataType;

    if (conditionalAttribute.evaluateCondition && !conditionalAttribute.evaluateCondition(component, features)) {
        return null;
    }

    if (dataAttributes.type === ComponentDataType.CODE_LIST) {
        return (
            <CodeListPropertyEntry
                isReadOnly={isPropertyReadOnly}
                isMandatory={isPropertyMandatory}
                component={component}
                name={name}
                onPropertyUpdate={onPropertyUpdate}
                validity={validity}
            />
        );
    }

    if (dataAttributes.type === ComponentDataType.VALUE_LIST) {
        return (
            <ValueListPropertyEntry
                component={component as PickingListComponent}
                name={name}
                descriptionText={descriptionText}
                isReadOnly={isPropertyReadOnly}
                isMandatory={isPropertyMandatory}
                validity={validity}
                onPropertyUpdate={onPropertyUpdate}
            />
        );
    }

    const attributeWithWarnings = dataAttributes as PropertyDataTypeWithWarning;
    const warnings = attributeWithWarnings.getWarnings
        ? attributeWithWarnings.getWarnings(component).map(warning => t(warning))
        : [];
    const validityClass = validity.isValid ? "" : "invalid-property";
    const valueValidityClass = validity.isValid ? "" : "invalid-property-value";
    const containerClass = DATA_TYPES_WITH_DIVIDER.includes(dataAttributes.type) ? "property-divider" : "";
    return (
        <div className={containerClass}>
            <div className="sidebar-row">
                <div className="property-name" aria-label={descriptionText}>
                    <span
                        className={`${
                            isPropertyMandatory ? "eui-form-element__label--mandatory" : ""
                        } ${validityClass}`}
                    >
                        {capitalise(descriptionText)}
                    </span>
                </div>
                <div
                    role="none"
                    key={propertyName}
                    data-testid={`container-${name}`}
                    id={propertyName}
                    className={`property-value ${valueValidityClass}`}
                    aria-label={t(valueToDisplay())}
                    onClick={handlePropertyValueClick}
                >
                    <PropertyValue
                        id={propertyName}
                        dataAttributes={dataAttributes}
                        description={descriptionText}
                        component={component}
                        name={name}
                        isReadOnly={isPropertyReadOnly}
                        isPropertySelected={isPropertySelected}
                        onPropertyUpdate={onPropertyUpdate}
                    />
                </div>
            </div>
            <PropertyError validity={validity} />
            {warnings.length > 0 && (
                <div className="sidebar-row">
                    <PropertyWarning name={name} warnings={warnings} />
                </div>
            )}
        </div>
    );
};

export default Property;
