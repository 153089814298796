/* eslint-disable no-console */
import React from "react";
import { InteropAlert } from "@emisgroup/single-spa-state-react";
import { useSelector } from "react-redux";
import { Routes as RoutedComponent } from "../routes/Index";
import { IRootState } from "../reducer/CombineReducer";

interface IProps {
    isRunningInApplicationFrame: boolean;
}
/**
 * Responsible for rendering the component as per route path
 */
export const Layout: React.FC<IProps> = (props: IProps) => {
    const interopState = useSelector((state: IRootState) => state.interopData);

    return (
        <div>
            <RoutedComponent />
            <InteropAlert suppress={props.isRunningInApplicationFrame} interopState={interopState} />
        </div>
    );
};

export default Layout;
