import { IListItem } from "@emisgroup/ui-kit-react";
import * as React from "react";
import ReactDOM from "react-dom";
import { KEYS } from "../../constants";
import MultiSelectOption from "./multiSelectOption";

const PREFERRED_MIN_HEIGHT = 100;

type MultiSelectOptionsProps = {
    id?: string;
    dataTestId?: string;
    isOpen?: boolean;
    dataSource: IListItem[];
    valuesToDisplay: any[];
    multiSelectDropdownElement: HTMLElement | null;
    onSelectionChange: (option, selected: boolean) => void;
    closeDropdown: () => void;
};

const MultiSelectOptions = React.forwardRef(
    (
        {
            id,
            dataTestId,
            isOpen,
            dataSource,
            valuesToDisplay,
            multiSelectDropdownElement,
            onSelectionChange,
            closeDropdown,
        }: MultiSelectOptionsProps,
        ref,
    ) => {
        const optionsContainerElement = React.useRef(document.createElement("div"));
        const dropdown = React.useRef<HTMLDivElement>(null);
        const nextElementToFocus = React.useRef<HTMLElement | null>(null);

        React.useImperativeHandle(ref, () => ({
            focusFirstElement: nextElement => {
                if (nextElement && !dropdown.current?.contains(nextElement)) {
                    dropdown.current?.getElementsByTagName("input")[0].focus();
                    nextElementToFocus.current = nextElement;
                }
            },
        }));

        React.useEffect(() => {
            document.body.appendChild(optionsContainerElement.current);

            return () => {
                document.body.removeChild(optionsContainerElement.current);
            };
        }, []);

        React.useEffect(() => {
            if (
                !isOpen ||
                !multiSelectDropdownElement ||
                !multiSelectDropdownElement.firstElementChild ||
                !dropdown.current
            ) {
                return;
            }

            const {
                bottom: selectionBottom,
                top: selectionTop,
                left: selectionLeft,
                width: selectionWidth,
            } = multiSelectDropdownElement.firstElementChild.getBoundingClientRect();

            const { height: dropdownHeight } = dropdown.current.getBoundingClientRect();

            dropdown.current.style.top = `${selectionBottom}px`;
            dropdown.current.style.left = `${selectionLeft}px`;
            dropdown.current.style.minWidth = `${selectionWidth}px`;

            const { bottom: clientBottom } = document.body.getBoundingClientRect();
            if (dropdownHeight + selectionBottom < clientBottom) {
                // no need to do anything with the height
                return;
            }

            const availableHeight = clientBottom - selectionBottom;
            if (availableHeight >= PREFERRED_MIN_HEIGHT) {
                dropdown.current.style.height = `${availableHeight}px`;
                return;
            }

            if (selectionTop - PREFERRED_MIN_HEIGHT > 0) {
                dropdown.current.style.top = `${selectionTop - dropdownHeight}px`;
                return;
            }

            // if no room above for the preferred minimum then the best bet is to leave it below
            dropdown.current.style.height = `${availableHeight}px`;
        }, [isOpen, multiSelectDropdownElement]);

        React.useEffect(() => {
            const handleOutsideClick = ev => {
                if (
                    isOpen &&
                    !dropdown.current?.contains(ev.target) &&
                    !multiSelectDropdownElement?.contains(ev.target)
                ) {
                    closeDropdown();
                }
            };

            document.addEventListener("mousedown", handleOutsideClick);
            return () => document.removeEventListener("mousedown", handleOutsideClick);
        });

        React.useEffect(() => {
            window.addEventListener("resize", closeDropdown);
            return () => window.removeEventListener("resize", closeDropdown);
        });

        React.useEffect(() => {
            const handleWheel = ev => {
                if (!dropdown.current?.contains(ev.target)) {
                    closeDropdown();
                }
            };

            if (isOpen) {
                window.addEventListener("wheel", handleWheel);
            }

            return () => {
                window.removeEventListener("wheel", handleWheel);
            };
        }, [isOpen]);

        if (!isOpen) {
            return null;
        }

        const renderOptions = () =>
            dataSource.map(
                (item, index: number): JSX.Element => (
                    <MultiSelectOption
                        key={item.text}
                        isSelected={valuesToDisplay.includes(item.value)}
                        item={item}
                        onSelectionChange={onSelectionChange}
                        data-testid={`${dataTestId ? `${dataTestId}-` : ""}option-${index + 1}`}
                    />
                ),
            );

        const focusFirstComponentElement = () => {
            if ((multiSelectDropdownElement?.firstElementChild as any)?.focus) {
                (multiSelectDropdownElement?.firstElementChild as HTMLElement).focus();
            }
        };

        const handleTabKey = (e: React.KeyboardEvent) => {
            const targetIsFirstChild =
                dropdown.current?.firstChild === e.target || dropdown.current?.firstChild?.contains(e.target as Node);
            const targetIsLastChild =
                dropdown.current?.lastChild === e.target || dropdown.current?.lastChild?.contains(e.target as Node);
            if (!e.shiftKey && targetIsLastChild) {
                e.preventDefault();
                nextElementToFocus.current?.focus();
            } else if (e.shiftKey && targetIsFirstChild) {
                e.preventDefault();
                focusFirstComponentElement();
            }
        };

        const handleKeyDown = (e: React.KeyboardEvent) => {
            switch (e.key) {
                case KEYS.ESC:
                    focusFirstComponentElement();
                    closeDropdown();
                    break;

                case KEYS.TAB:
                    handleTabKey(e);
                    break;

                default:
                    break;
            }
        };

        const handleBlur = (e: React.FocusEvent) => {
            if (
                dropdown.current?.contains(e.relatedTarget as Node) ||
                multiSelectDropdownElement?.contains(e.relatedTarget as Node)
            ) {
                e.stopPropagation();
            } else {
                closeDropdown();
            }
        };

        const options = (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <div
                id={id}
                ref={dropdown}
                className="multiSelect-dropdown"
                tabIndex={-1}
                role="list"
                onKeyDown={handleKeyDown}
                onBlur={handleBlur}
            >
                {renderOptions()}
            </div>
        );

        return ReactDOM.createPortal(options, optionsContainerElement.current);
    },
);

export default MultiSelectOptions;
