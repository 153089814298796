import * as React from "react";
import { IntlProvider } from "@emisgroup/application-intl";
import AppModeContext from "../context/appMode";
import ConfigContext, {
    CLINICAL_CONTENT_QUERY_DISPLAY_TYPES,
    SAVED_CONTENT_ENABLED,
    VISIBILITY_RULES_SELECT_QUERY_ENABLED,
    CODED_COMPONENTS_ENABLED,
    NUMERIC_VALUE_COMPONENT_ENABLED,
    CODE_EXTRA_INFORMATION_PROPERTIES,
    SUPPORT_MULTI_COLUMN,
    ASSOCIATED_TEXT_IS_CLINICAL_CONTENT,
    USE_TEMPLATE_PICKER,
    PERSPECTIVES,
} from "../context/config";
import {
    AuthContext,
    ComponentConfigDataContext,
    RunningTemplateContext,
    TemplateContext,
    TemplateHistoryContext,
} from "../context";
import { enGBMessages } from "../locales/en-GB/translation";
import { AppMode, CanvasItem, ComponentContainer, ComponentType, TemplateData } from "../types";

export const AppModeProvider = ({ initialMode, children }) => {
    return (
        <AppModeContext.Provider
            value={{
                mode: initialMode,
                allowedModes: [AppMode.EDIT, AppMode.READ, AppMode.RUN],
                updateMode: () => {},
                setReadOnly: () => {},
            }}
        >
            {children}
        </AppModeContext.Provider>
    );
};

export const ComponentConfigDataProvider = ({ initialComponentConfig, children }) => (
    <ComponentConfigDataContext.Provider
        value={{ componentsConfig: initialComponentConfig, setComponentsConfig: () => {} }}
    >
        {children}
    </ComponentConfigDataContext.Provider>
);

const features = {
    [CLINICAL_CONTENT_QUERY_DISPLAY_TYPES]: [],
    [SAVED_CONTENT_ENABLED]: true,
    [VISIBILITY_RULES_SELECT_QUERY_ENABLED]: true,
    [CODED_COMPONENTS_ENABLED]: true,
    [NUMERIC_VALUE_COMPONENT_ENABLED]: true,
    [CODE_EXTRA_INFORMATION_PROPERTIES]: [],
    [SUPPORT_MULTI_COLUMN]: true,
    [ASSOCIATED_TEXT_IS_CLINICAL_CONTENT]: false,
    [USE_TEMPLATE_PICKER]: true,
    [PERSPECTIVES]: [],
};

export const ConfigProvider = ({ freeTextMaxChars, children }) => (
    <ConfigContext.Provider value={{ features, freeTextMaxChars, contentLibraryUrl: "", platformUrl: "" }}>
        {children}
    </ConfigContext.Provider>
);

const getHistoryKey = (type, codeId) => {
    switch (type) {
        case ComponentType.CODED:
            return `${codeId}`;

        case ComponentType.DIARY_ENTRY:
            return `history-${codeId}-diary`;

        default:
            return undefined;
    }
};

export const TemplateHistoryProvider = ({
    initialHistory,
    initialHistoryWithErrors,
    initialAreConfidentialItemsRemoved,
    children,
}) => (
    <TemplateHistoryContext.Provider
        value={{
            history: initialHistory || {},
            historyWithErrors: initialHistoryWithErrors || [],
            areConfidentialItemsRemoved: Boolean(initialAreConfidentialItemsRemoved),
            retryHistory: () => {},
            isHistoryRetry: false,
            updateHistory: () => {},
            getHistoryKey: (_, type, codeId) => getHistoryKey(type, codeId),
        }}
    >
        {children}
    </TemplateHistoryContext.Provider>
);

export const RunningTemplateProvider = ({ initialData, children }) => {
    const [templateData, setTemplateData] = React.useState<TemplateData>(initialData);

    return (
        <RunningTemplateContext.Provider
            value={{
                templateData,
                runDate: new Date(),
                initialiseTemplateData: () => {},
                updateTemplateData: setTemplateData,
            }}
        >
            {children}
        </RunningTemplateContext.Provider>
    );
};

export const TemplateProvider = ({ initialDefinition, children }) => (
    <TemplateContext.Provider
        value={{
            templateDefinition: initialDefinition,
            setTemplateDefinition: () => {},
            clearTemplateDefinition: () => {},
            groupedItems: [],
            setGroupedItems: () => {},
            selectedItem: {} as CanvasItem,
            setSelectedItem: () => {},
            containerOfSelectedItem: {} as ComponentContainer,
            history: [],
            currentIndex: 0,
            goBack: () => {},
            goForward: () => {},
            hasDefinitionChanged: false,
            invalidComponentDefinitionIds: [],
            setInvalidComponentDefinitionIds: () => {},
        }}
    >
        {children}
    </TemplateContext.Provider>
);

export const AuthProvider = ({ token = "token", children }) => (
    <AuthContext.Provider value={{ getBearerToken: () => Promise.resolve(token) }}>{children}</AuthContext.Provider>
);

const namespace = "Anything";
const resources = {
    "en-GB": {
        [namespace]: enGBMessages,
    },
};

export const IntlWrapper = (content: JSX.Element) => {
    return (
        <IntlProvider resources={resources} defaultNS={namespace}>
            {content}
        </IntlProvider>
    );
};
