/* eslint-disable @typescript-eslint/interface-name-prefix */
import { connectRouter, RouterState } from "connected-react-router";
import { combineReducers, Reducer } from "redux";
import { InteropReducer } from "@emisgroup/single-spa-state-management";

export interface IRootState {
    /**
     * Router
     */
    router: RouterState;
    /**
     * InteropData - This data will be mirrored between the application frame and this module.
     */
    interopData: ReturnType<typeof InteropReducer>;
}

/**
 * Returns the list of reducers
 */
const createRootReducer: (history: any) => Reducer<IRootState> = (history: any): Reducer<IRootState> =>
    combineReducers<IRootState>({
        interopData: InteropReducer,
        router: connectRouter(history),
    });

export default createRootReducer;
