import * as React from "react";
import { useDrop } from "react-dnd";
import { $enum } from "ts-enum-util";
import {
    CanvasItem,
    CanvasItemClickHandler,
    ComponentType,
    Panel,
    PanelComponent,
} from "@emisgroup/clint-templates-common";
import useCreateItem from "../uiComponents/useCreateItem";
import useMoveItem from "../uiComponents/useMoveItem";
import { canDropItemInto, getContainerDropInIndicatorHeight } from "../utils/dragDropUtils";

type DroppablePanelComponentProps = {
    children: React.ReactNode | React.ReactNodeArray | null;
    component: Panel;
    dragDropEnabled: boolean;
    arePropertiesValid: boolean;
    onSelect: CanvasItemClickHandler;
};

const DroppablePanelComponent = (props: DroppablePanelComponentProps) => {
    const panelContentRef = React.useRef<HTMLDivElement>(null);
    const dropIndicatorRef = React.useRef<HTMLDivElement>(null);
    const { component, children, dragDropEnabled } = props;
    const { handleItemAddition, newItemProperties } = useCreateItem(component);
    const { moveItem, moveItemDialog } = useMoveItem(component);

    const [{ canDrop }, drop] = useDrop({
        accept: $enum(ComponentType).getValues(),
        canDrop(item: CanvasItem, monitor) {
            return monitor.isOver({ shallow: true }) && canDropItemInto(item, component);
        },
        collect: monitor => ({
            canDrop: monitor.canDrop(),
        }),
        drop(item: CanvasItem) {
            if (item.id === "") {
                handleItemAddition(item, 0, 0);
            } else {
                moveItem(item, 0, 0);
            }
        },
    });

    React.useEffect(() => {
        if (dragDropEnabled && dropIndicatorRef.current) {
            const dropIndicator = dropIndicatorRef.current as HTMLElement;
            dropIndicator.style.height = getContainerDropInIndicatorHeight(canDrop, component.members.length === 0);
        }
    }, [canDrop, component.members.length]);

    const childrenWithAddition = React.Children.map(children, child => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { handleItemAddition });
        }
        return child;
    });

    if (dragDropEnabled) {
        drop(panelContentRef);
    }

    return (
        <>
            <PanelComponent {...props}>
                <div ref={panelContentRef}>
                    {dragDropEnabled && (
                        <div
                            ref={dropIndicatorRef}
                            className="drop-indicator"
                            data-testid={`${component.id}-drop-into-indicator`}
                        />
                    )}
                    {childrenWithAddition}
                </div>
            </PanelComponent>
            {newItemProperties && newItemProperties()}
            {moveItemDialog}
        </>
    );
};

export default DroppablePanelComponent;
