import * as React from "react";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { IChildrenProp } from "./Types";
import { IAppStore } from "./store/AppStore";

interface IProps extends IChildrenProp {
    appStore: IAppStore;
}

/**
 * Responsible for rendering the IntlProvider component
 */
const StateAndRouterProvider: React.FC<IProps> = (props: IProps) => {
    return (
        <Provider store={props.appStore.store}>
            <ConnectedRouter history={props.appStore.history}>{props.children}</ConnectedRouter>
        </Provider>
    );
};

export { StateAndRouterProvider };
