import * as React from "react";
import { ContentDesigner, contentToText, ContentViewer } from "@emisgroup/clint-content";
import { DisplayType, UpdateableEditorEntity } from "@emisgroup/clint-content/lib/types";
import { useTranslation } from "@emisgroup/application-intl";
import ConfigContext, {
    CLINICAL_CONTENT_QUERY_DISPLAY_TYPES,
    ASSOCIATED_TEXT_IS_CLINICAL_CONTENT,
} from "../context/config";
import AuthContext from "../context/auth";
import { MouseEventHandler } from "../types";
import MultilineTextEntry from "./multilineTextEntry";

type ComponentAssociatedTextEditProps = {
    associatedText?: string;
    isSelected: boolean;
    onSelect: MouseEventHandler;
    onPropertyUpdate: (params: { propertyName: string; propertyValue: any }) => void;
};

const getDesignerContent = (associatedText?: string) =>
    typeof associatedText === "string" ? { blocks: [], entityMap: {} } : associatedText;
export const ComponentAssociatedTextEdit = ({
    associatedText,
    isSelected,
    onSelect,
    onPropertyUpdate,
}: ComponentAssociatedTextEditProps) => {
    const { t } = useTranslation();
    const { features } = React.useContext(ConfigContext);
    if (!features[ASSOCIATED_TEXT_IS_CLINICAL_CONTENT]) {
        const text = (associatedText as any)?.blocks ? contentToText(associatedText) : associatedText;
        return (
            <MultilineTextEntry
                text={text}
                placeholder={t("components.associatedText.withEllipsis")}
                disabled
                ariaLabel={t("components.associatedText.name")}
            />
        );
    }

    const queryDisplayTypes = features[CLINICAL_CONTENT_QUERY_DISPLAY_TYPES].filter(type =>
        [DisplayType.Text, DisplayType.Value].includes(type),
    );

    const { getBearerToken } = React.useContext(AuthContext);
    const contentLibraryConfig = {
        url: process.env.APP_CONTENT_LIBRARY_URL as string,
        getBearerToken,
    };

    const designerContent = getDesignerContent(associatedText);

    const handleBlur = (value: any) => {
        if (onPropertyUpdate && JSON.stringify(value) !== JSON.stringify(associatedText)) {
            onPropertyUpdate({ propertyName: "associatedText", propertyValue: value });
        }
    };

    const handleFocus = () => {
        if (onSelect) {
            onSelect({ stopPropagation: () => {}, preventDefault: () => {} } as React.MouseEvent);
        }
    };

    const handleSelectEntity = (entity: UpdateableEditorEntity) => {
        window.dispatchEvent(new CustomEvent("associated-text-entity-selected", { detail: { entity } }));
    };

    const handleLeaveEntity = () => {
        window.dispatchEvent(new CustomEvent("associated-text-entity-leave"));
    };

    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <div
            role="none"
            className={`associated-text-designer${isSelected ? " selected" : ""}`}
            data-testid="associated-text-designer"
            onClick={onSelect}
            onFocus={handleFocus}
        >
            <ContentDesigner
                className="associated-text-content-designer"
                showHeaders={false}
                showStyles={false}
                allowTooltips={false}
                allowLinks={false}
                queryDisplayTypes={queryDisplayTypes}
                placeholder={t("components.associatedText.withEllipsis")}
                autoFocus={false}
                onBlur={handleBlur}
                initialContent={designerContent}
                contentLibraryConfig={contentLibraryConfig}
                onSelectEntity={handleSelectEntity}
                onLeaveEntity={handleLeaveEntity}
            />
        </div>
    );
};

type ComponentAssociatedTextViewProps = { associatedText?: string };
export const ComponentAssociatedTextView = ({ associatedText }: ComponentAssociatedTextViewProps) => {
    const { t } = useTranslation();
    const { features } = React.useContext(ConfigContext);
    if (!features[ASSOCIATED_TEXT_IS_CLINICAL_CONTENT]) {
        return (
            <MultilineTextEntry
                text={associatedText}
                placeholder={t("components.associatedText.withEllipsis")}
                disabled
            />
        );
    }

    const designerContent = getDesignerContent(associatedText);
    return <ContentViewer content={designerContent} />;
};
