/* eslint-disable react/style-prop-object */
import React from "react";
import { Button, ButtonGroup } from "@emisgroup/ui-kit-react";
import { ActionButton, ActionButtonStyle } from "../types";

function getButtonStyle(actionButtonStyle: ActionButtonStyle) {
    return actionButtonStyle === ActionButtonStyle.PRIMARY ? "primary" : "secondary";
}

type ActionButtonsProps = {
    actionButtons: ActionButton[];
    disabled?: boolean;
    onRaiseAction?: (actionButton: ActionButton) => void;
};
const ActionButtons = ({ actionButtons, disabled, onRaiseAction }: ActionButtonsProps) => {
    return (
        <div data-testid="action-buttons" className="canvas-buttons">
            <ButtonGroup>
                {React.Children.toArray(
                    actionButtons.map(actionButton => (
                        <Button
                            className="canvas-button"
                            disabled={disabled === true}
                            data-testid={`${actionButton.action}-event-button`}
                            ariaLabel={actionButton.label}
                            variant={getButtonStyle(actionButton.style)}
                            onClick={onRaiseAction ? () => onRaiseAction(actionButton) : undefined}
                        >
                            {actionButton.label}
                        </Button>
                    )),
                )}
            </ButtonGroup>
        </div>
    );
};

export default ActionButtons;
